import './MyPage.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleUser} from "@fortawesome/free-solid-svg-icons";
import {_ProfileRow} from "./_ProfileRow";
import Footer from "../../../component/footer/Footer";
import React, {useEffect, useState} from "react";
import axios from "axios";

function MyPage() {
    const [profileData, setProfileData] = useState(null);
    const [profileImage, setProfileImage] = useState(null);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await axios.get("https://air.aiia-gcu.com/user/myInfo", {
                    withCredentials: true
                });
                if (response.data.statusCode === 200) {
                    const data = response.data.data;
                    setProfileData({
                        profileImage: data.profileImage || null,
                        nickname: data.name,
                        studentId: data.grade,
                        major: data.major,
                        point: data.point,
                        unit: data.unit,
                        position: data.position,
                        email: data.email,
                        phoneNumber: data.phoneNumber,
                    });
                    setProfileImage(data.profileImage || null);
                }
            } catch (error) {
                console.error("Error fetching profile data:", error);
            }
        };
        fetchProfileData();
    }, []);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            const fileExtension = file.name.split(".").pop();
            const presignedRes = await axios.post("https://air.aiia-gcu.com/user/profileImage", {
                fileExtension: fileExtension
            }, { withCredentials: true });

            if (!presignedRes.data.success) {
                throw new Error("Presigned URL 생성 실패");
            }

            const uploadUrl = presignedRes.data.payload;
            await axios.put(uploadUrl, file, {
                headers: { "Content-Type": file.type }
            });
            window.location.reload();
        } catch (error) {
            console.error("이미지 업로드 실패:", error);
        }
    };

    const profile_data = profileData ? [
        {label: "별명", value: profileData.nickname},
        {label: "학번", value: profileData.studentId},
        {label: "전공", value: profileData.major},
        {label: "번호", value: profileData.phoneNumber},
        {label: "메일", value: profileData.email},
        {label: "유닛", value: profileData.unit},
        {label: "직급", value: profileData.position},
    ] : [];

    return (
        <div className="screen bg-image-gradient">
            <div className="content-wrapper">
                <div className="card-container" style={{width: '380px', height: '520px'}}>
                    <h4 className="h4-txt">내 계정</h4>
                    <label htmlFor="file-upload">
                        {profileImage ? (
                            <img
                                src={profileImage}
                                alt="Profile"
                                className="profile-image"
                            />
                        ) : (
                            <FontAwesomeIcon className="fa-circle-user" icon={faCircleUser}/>
                        )}
                    </label>
                    <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        style={{display: "none"}}
                        onChange={handleImageUpload}
                    />
                    <p className="modal-txt">{profileData ? profileData.nickname : "로딩 중..."}</p>
                    <div>
                        <span className="sub-calendar-txt">포인트</span>
                        <span className="sub-calendar-txt" style={{color: "var(--bs-primary)"}}>
                            &nbsp;{profileData ? profileData.point + "p" : "로딩 중..."}
                        </span>
                    </div>
                </div>
                <div className="card-container profile-data-container" style={{width: '700px', height: '520px'}}>
                    {profile_data.map((data, index) => (
                        <_ProfileRow key={index} index={index} label={data.label} value={data.value}/>
                    ))}
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default MyPage;
