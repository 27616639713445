import Button from "react-bootstrap/Button";
import {useEffect} from "react";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    requestAttendanceCodeAction,
    setFocusedDate
} from "../../../../redux/admin_service/attendance_management/AttendanceManagementAction";
import Card from "react-bootstrap/Card";
import Grid from "@mui/material/Grid2";

// Todo. API 수정 필요
function AttendanceCodeGeneratorPage() {
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.attendanceManagement);
    const code = selector.code;

    const handleGenerateCode = async () => {
        const now = new Date();
        const openAt = now.toISOString();
        const closeAtDate = new Date(now);
        closeAtDate.setMinutes(closeAtDate.getMinutes() + 5);
        const closeAt = closeAtDate.toISOString();
        const cutOffAtDate = new Date(now);
        cutOffAtDate.setMinutes(cutOffAtDate.getMinutes() + 90);
        const cutOffAt = cutOffAtDate.toISOString();
        await dispatch(requestAttendanceCodeAction({
            openAt,
            closeAt,
            cutOffAt
        }));
    };
    const navigateToDailyAttendancePage = () => {
        let today = new Date();
        let formattedDate = dayjs(today).format("YYYY-MM-DD");
        dispatch(setFocusedDate(today.toString()));
        navigator("/admin/attendance/calendar/daily?date=" + formattedDate);
    };

    return (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "start",
            justifyContent: "center"
        }}>
            <Card style={{
                padding: "140px 120px",
                backgroundColor: "white",
                borderRadius: 12,
                boxShadow: "0 0 28px rgba(0, 0, 0, 0.1)",
                position: "relative",
            }}>
                <Grid container spacing="19px">
                    {
                        typeof code === "string"
                            ? code.toString().split("").map((digit, index) => (
                                <div
                                    key={index}
                                    style={{
                                        width: 44,
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "center",
                                        borderBottom: "1px solid var(--sub-title4)",
                                        boxSizing: "border-box",
                                        fontFamily: 'Pretendard, sans-serif',
                                        fontWeight: "revert",
                                        fontSize: 80,
                                        lineHeight: "70px",
                                    }}>
                                    {digit}
                                </div>
                            ))
                            : Array.from({length: 6}, (_, i) => (
                                <div
                                    key={i.toString()}
                                    style={{
                                        width: 44,
                                        height: 70,
                                        borderBottom: "1px solid var(--sub-title4)",
                                        boxSizing: "border-box",
                                    }}
                                />
                            ))
                    }
                </Grid>
                <Button
                    className="btn-txt"
                    variant="primary"
                    disabled={code !== null}
                    onClick={handleGenerateCode}
                    style={{
                        position: "relative",
                        marginTop: 40,
                        height: 64,
                        backgroundColor: code === null ? "var(--bs-primary)" : "var(--sub-title4)",
                        color: code === null ? "white" : "var(--sub-title2)",
                        border: code === null ? "none" : "1px solid var(--sub-title2)",
                        borderRadius: 8,
                    }}>
                    {code === null ? "출석 코드 생성" : "생성 완료"}
                </Button>
                <Button
                    className="btn-txt"
                    variant="primary"
                    onClick={navigateToDailyAttendancePage}
                    style={{
                        height: 64,
                        marginTop: 20,
                        position: "relative",
                        backgroundColor: "var(--blue3)",
                        color: "var(--bs-primary)",
                        borderRadius: 8
                    }}>
                    오늘 출석 현황 확인 &nbsp; &#62;
                </Button>
            </Card>
        </div>
    );
}

export default AttendanceCodeGeneratorPage;