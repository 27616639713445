import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import "./MemberPage.css";
import {useEffect, useState} from "react";

function MemberCard({ member }) {
    return (
        <Grid item>
            <div className="member-card">
                {member.profileImage ? (
                    <img
                        src={member.profileImage}
                        alt={member.name}
                        className="member-profile-image"
                    />
                ) : (
                    <FontAwesomeIcon icon={faCircleUser}/>
                )}
                <p className="profile-card-title-txt" style={{ textAlign: "center" }}>
                    {member.name}
                </p>
                <p className="profile-card-subtitle-txt" style={{ textAlign: "center" }}>
                    {member.department}<br />
                    {member.role}<br />
                    {member.unit.map((unit, index) => (
                        <span key={index}>
                            {unit}
                            {0 < index && index < member.unit.length - 1 ? " / " : ""}
                        </span>
                    ))}
                </p>
            </div>
        </Grid>
    );
}



export function _UnitContainer({unit, members}) {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    if (isMobile) {
        return (
            <div style={{width: "auto", marginBottom: 100}}>
                <h4
                    id={unit}
                    className="h4-txt"
                    style={{color: "var(--bs-primary)", paddingBottom: 24}}>
                    {unit}
                </h4>
                <Grid container spacing="50px">{
                    members.map((member, index) => (
                        <MemberCard key={index} member={member}/>
                    ))
                }</Grid>
            </div>

        );
    }
    else {
        return (
            <div style={{width: 998, marginBottom: 100}}>
                <h4
                    id={unit}
                    className="h4-txt"
                    style={{color: "var(--bs-primary)", paddingBottom: 24}}>
                    {unit}
                </h4>
                <Grid container spacing="50px">{
                    members.map((member, index) => (
                        <MemberCard key={index} member={member}/>
                    ))
                }</Grid>
            </div>

        );
    }
}