import React from 'react';

export function _DotsPagination({ focusedIndex, handleClick }) {
    const dotStyle = (index) => {
        return {
            width: `${focusedIndex === index ? 1.2 : 1}em`,
            height: `${focusedIndex === index ? 1.2 : 1}em`,
            borderRadius: "50%",
            marginTop: index === 0 ? 0 : 20,
            padding: 5,
            backgroundColor: focusedIndex === index ? "var(--bs-primary)" : "var(--not-selected)",
            cursor: "pointer",
        };
    };

    return (
        <div
            style={{
                position: "absolute",
                top: "55%",
                left: "7em",
                transform: "translateY(-50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {[...Array(5)].map((_, index) => (
                <div
                    key={index}
                    onClick={() => handleClick(index)}
                    style={dotStyle(index)}
                />
            ))}
        </div>
    );
}