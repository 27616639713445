import {VirtuosoTableComponents} from "../../../../component/table/VirtuosoTableComponents";
import {fixedHeaderContent} from "../../../../component/table/FixedHeaderContent";
import {rowContent} from "../../../../component/table/RowContent";
import {TableVirtuoso} from "react-virtuoso";
import React from "react";
import DropdownChip from "../../../../component/dropdown/dropdown_chip/DropdownChip";
import NoteInputForm from "../../../../component/input_form/NoteInputForm";
import {Link} from "react-router-dom";
import AttendanceStatusEnum from "../../../../dto/enum/AttendanceStatusEnum";

function TableTitle({ children }) {
    return (
        <div
            className="h4-txt table-title"
            style={{color: "var(--bs-primary)"}}
        >
            {children}
        </div>
    );
}

function CustomAT({attributes, data, onChangedAttendanceStatus, onChangedNote}) {
    const setContent = (_index, row, attribute) => {
        if (attribute.dataKey === 'status') {
            return (
                <DropdownChip
                    hint="출석"
                    init={AttendanceStatusEnum.getValue(row[attribute.dataKey])}
                    items={["출석", "지각", "결석"]}
                    onClick={(index) => onChangedAttendanceStatus(row["userId"], index)}
                />
            );
        }

        if (attribute.dataKey === 'note') {
            return (
                <NoteInputForm
                    value={row[attribute.dataKey]}
                    onUpdated={(value) => onChangedNote(row["userId"], value)}
                />
            );
        }

        if (attribute.dataKey === 'percent') {
            return row[attribute.dataKey] + '%';
        }

        if (attribute.dataKey === 'all') {
            return (
                <Link
                    to='/admin/attendance/list'
                    style={{color: "rgba(0, 0, 0, 0.87)"}}>
                    확인하기
                </Link>
            );
        }

        return row[attribute.dataKey];
    };

    return (
        <TableVirtuoso
            data={data}
            components={VirtuosoTableComponents}
            fixedHeaderContent={() => fixedHeaderContent(attributes)}
            itemContent={(index, row) => rowContent(index, row, attributes, setContent)}
            style={{
                marginBottom: 16,
                "--Paper-shadow": "none",
                borderTop: "4px solid var(--bs-primary)",
                borderBottom: "none",
                borderRadius: 0,
                height: data.length > 0 ? "100%" : 70,
            }}
        />
    );
}

export {
    TableTitle,
    CustomAT
}