
class AttendanceStatusEnum {
    static getValue = (key) => {
        if (key === "ATTENDANCE") return 0;
        if (key === "LATE") return 1;
        if (key === "ABSENCE") return 2;
        if (key === "NOT_REQUIRED") return 2;
        throw new Error("Invalid key");
    }
    static getKey = (value) => {
        if (value === 0) return "ATTENDANCE";
        if (value === 1) return "LATE";
        if (value === 2) return "ABSENCE";
        if (value === 2) return "NOT_REQUIRED";
        throw new Error("Invalid value");
    }
}

export default AttendanceStatusEnum;