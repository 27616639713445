import { createSlice } from '@reduxjs/toolkit';
import { fetchWeekEvents, fetchMonthEvents, updateSchedule } from './UserCalendarAction';

const initialState = {
    selectedDate: (new Date()).toString(),
    monthEvents: {},
    weekEvents: {},
    status: 'idle',
    error: null,
    modal: false,
    newEvents: [],
};

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setModal: (state, action) => {
            state.modal = action.payload;
        },
        setNewEvents: (state, action) => {
            state.newEvents = action.payload;
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWeekEvents.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWeekEvents.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.weekEvents = action.payload;
            })
            .addCase(fetchWeekEvents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(fetchMonthEvents.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMonthEvents.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.monthEvents = action.payload;
            })
            .addCase(fetchMonthEvents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateSchedule.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateSchedule.fulfilled, (state) => {
                state.status = 'succeeded';
                state.modal = false;
            })
            .addCase(updateSchedule.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { setModal, setNewEvents, setSelectedDate } = calendarSlice.actions;
export default calendarSlice.reducer;
