import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import dayjs from 'dayjs';

const API_BASE_URL = 'https://air.aiia-gcu.com/api/calendar/schedule';

export const fetchMonthEvents = createAsyncThunk(
    'calendar/fetchMonthEvents',
    async (date, { rejectWithValue }) => {
        try {
            const token = sessionStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            date = new Date(date);
            const year = date.getFullYear().toString();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');

            const response = await axios.get(`${API_BASE_URL}/list`, {
                params: { year, month },
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            const formattedEvents = {};
            response.data.data.forEach(event => {
                const dateKey = dayjs(event.startAt).format('YYYY-MM-DD');
                if (!formattedEvents[dateKey]) {
                    formattedEvents[dateKey] = [];
                }
                formattedEvents[dateKey].push({
                    id: event.id,
                    title: event.name,
                    startTime: event.startAt || null,
                    endTime: event.endAt || null
                });
            });

            return formattedEvents;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const updateSchedule = createAsyncThunk(
    'calendar/updateSchedule',
    async ({ date, events }, { rejectWithValue, dispatch }) => {
        try {
            const token = sessionStorage.getItem('token');
            if (!token) throw new Error('No authentication token found');

            const formattedDate = dayjs(date).format('YYYY-MM-DD');

            const formattedEvents = events.map(event => ({
                ...event,
                startAt: event.startAt || '00:00',
                endAt: event.endAt || '23:59',
            }));

            await axios.post(
                `${API_BASE_URL}/update`,
                {
                    date: formattedDate,
                    data: formattedEvents,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                }
            );

            dispatch(fetchMonthEvents(new Date(date)));
            dispatch (fetchWeekEvents(new Date(date)));
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const fetchWeekEvents = createAsyncThunk(
    'calendar/fetchWeekEvents',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/week-list`, {
                withCredentials: true,
            });
            const eventData = response.data.data;

            const groupedEvents = eventData.reduce((acc, event) => {
                const eventDate = dayjs(event.startAt).format('YYYY-MM-DD');
                if (!acc[eventDate]) acc[eventDate] = [];
                acc[eventDate].push({
                    id: event.id,
                    title: event.name,
                    startTime: event.startAt || null,
                    endTime: event.endAt || null,
                });
                return acc;
            }, {});

            return groupedEvents;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);