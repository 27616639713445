import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import '../../../component/calendar/Calendar.css';
import React, {useCallback, useEffect} from "react";
import {UserAttendanceCalendarTileClassName} from "../../../component/calendar/TileClassName";
import {UserAttendanceCalendarTileContent} from "../../../component/calendar/TileContent";
import Footer from "../../../component/footer/Footer";
import './AttendancePage.css';
import {useDispatch, useSelector} from "react-redux";
import {fetchAttendanceAction} from "../../../redux/user_service/attendance/UserAttendanceAction";
import CodeInputForm from "./_CodeInputForm";

function AttendancePage() {
    const [date, setDate] = React.useState(new Date());
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.attendance);
    const attendance = selector.attendance.map((item, _) => {
        return {
            date: new Date(item.date),
            status: item.status
        };
    });

    const refreshAttendance = useCallback(() => {
        dispatch(fetchAttendanceAction());
    }, [dispatch]);

    const handleDateChange = (date) => setDate(date)
    useEffect(() => {
        refreshAttendance();
    }, [refreshAttendance]);


    return (
        <div className="page-div bg-image-gradient">
            <div
                className="attendance-container">
                <div
                    className="calendar-section">
                    <h3
                        className="modal-txt"
                        style={{color: "var(--blue1)", fontWeight: 500}}>
                        출석 현황
                    </h3>
                    <Calendar
                        className="calendar"
                        locale="en-US"
                        onChange={handleDateChange}
                        next2Label={null}
                        prev2Label={null}
                        value={date}
                        showNeighboringMonth={false}
                        tileClassName={({ _, date, view }) =>
                            UserAttendanceCalendarTileClassName(date, view, attendance)}
                        tileContent={({ date, view }) =>
                            UserAttendanceCalendarTileContent(date, view, attendance)}
                    />

                </div>
                <CodeInputForm onAttendanceSuccess={refreshAttendance} />
            </div>
            <Footer/>
        </div>
    );
}

export default AttendancePage;