import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import {
    getAttendanceRate,
    getRequiredUserList,
    getUserListToAttend,
    updateAttendanceStatus,
} from "../../../api/admin_side/AttendanceAPI";
import dayjs from "dayjs";
import {instance} from "../../../api/_base";

const setFocusedDate = createAction('admin/attendance/setFocusedDate');

const fetchMonthlyAttendanceAction = createAsyncThunk(
    "admin/attendance/monthly",
    async (action) => {
        try {
            console.log("[Attendance] action: fetchAttendance (start)");

            console.log(`[Attendance] action: fetchAttendance (done)`);
            return {};
        }
        catch (e) {
            console.log("[Attendance] action: fetchAttendance (error)");
            console.log(e);
        }
    }
);

const fetchDailyAttendanceAction = createAsyncThunk(
    "admin/attendance/daily",
    async (arg) => {
        try {
            console.log("[Attendance] action: fetchDailyAttendance (start)")

            let request = {date: arg.date};
            let response = await getUserListToAttend(request);

            console.log(`[Attendance] action: fetchDailyAttendance (done)`);

            return response.data.map((item, index) => {
                return {id: index, ...item};
            });
        }
        catch (e) {
            console.log("[Attendance] action: fetchDailyAttendance (error)");
            console.log(e);
            return [];
        }
    }
);

const modifyDailyAttendanceAction = createAsyncThunk(
    "admin/attendance/modifyDaily",
    async (arg) => {
        try {
            console.log("[Attendance] action: modifyDailyAttendance (start)");

            console.log(arg);
            let request = {
                date: arg.date,
                attendanceList: arg.attendanceList
            }
            let response = await updateAttendanceStatus(request);

            console.log(`[Attendance] action: modifyDailyAttendance (done)`);
            return response.data;
        }
        catch (e) {
            console.log("[Attendance] action: modifyDailyAttendance (error)");
            console.log(e);
        }
    }
);

const fetchRequiredUserListAction = createAsyncThunk(
    "admin/attendance/userList",
    async (arg) => {
        try {
            console.log("[Attendance] action: fetchRequiredUserList (start)");

            let request = {date: arg.date};
            let response = await getRequiredUserList(request);

            console.log("[Attendance] API response: ", response.data);

            console.log(`[Attendance] action: getAttendanceUserList (done)`);

            if (Object.keys(response.data).length === 0) {
                return {
                    "Flutter": [],
                    "React": [],
                    "Spring": [],
                    "AI": [],
                    "Design": []
                };
            }

            return response.data;
        }
        catch (e) {
            console.log("[Attendance] action: fetchRequiredUserList (error)");
            console.log(e);
        }
    }
);

const modifyRequiredUserList = createAsyncThunk(
    "admin/attendance/updateUserList",
    async (arg) => {
        try {
            console.log("[Attendance] action: updateRequiredUserList (start)");
            let request = {
                date: arg.date,
                attendanceRequiredStatus: arg.attendanceRequiredStatus
            }
            let response = await updateAttendanceStatus(request);
            console.log(`[Attendance] action: updateRequiredUserList (done)\n`, response);
            return null;
        }
        catch (e) {
            console.log("[Attendance] action: updateRequiredUserList (error)");
            console.log(e);
        }
    }
);

const fetchAllAttendanceRateAction = createAsyncThunk(
    "admin/attendance/all",
    async (action) => {
        try {
            console.log("[Attendance] action: fetchAllAttendance (start)");

            let response = await getAttendanceRate();
            console.log(`[Attendance] action: fetchAllAttendance (done)\n`, response);

            return response.data.map((item, index) => {
                return {...item, id: index}
            });
        }
        catch (e) {
            console.log("[Attendance] action: fetchAllAttendance (error)");
            console.log(e);
        }
    }
)



const requestAttendanceCodeAction = createAsyncThunk(
    "admin/attendance/create",
    async () => {
        try {
            console.log("[Attendance] action: requestAttendanceCode (start)");

            const today = dayjs().format("YYYY-MM-DD");
            const now = dayjs();
            const dailyResponse = await instance.post("/attendance/daily", null, {
                params: { date: today }
            });

            if (dailyResponse?.data?.statusCode !== 200) {
                throw new Error("일일 출석부 생성에 실패했습니다.");
            }

            const codeParams = {
                openAt: now.format("YYYY-MM-DDTHH:mm:ss"),
                closeAt: now.add(5, 'minute').format("YYYY-MM-DDTHH:mm:ss"),
                cutOffAt: now.add(2, 'hour').format("YYYY-MM-DDTHH:mm:ss")
            };

            const codeResponse = await instance.post("/attendance/code", null, {
                params: codeParams
            });

            if (codeResponse?.data?.statusCode !== 200) {
                throw new Error("출석 코드 생성에 실패했습니다.");
            }

            return codeResponse.data.data;

        } catch (error) {
            console.log("[Attendance] action: requestAttendanceCode (error)");
            console.log(error);
            throw error;
        }
    }
);
export {
    setFocusedDate,
    fetchMonthlyAttendanceAction,
    fetchDailyAttendanceAction,
    modifyDailyAttendanceAction,
    fetchRequiredUserListAction,
    modifyRequiredUserList,
    fetchAllAttendanceRateAction,
    requestAttendanceCodeAction
}