import { useEffect, useRef, useState } from "react";
import { _UnitContainer } from "./_UnitContainer";
import { _DotsPagination } from "./_DotsPagination";
import Footer from "../../../component/footer/Footer";
import axios from "axios";

function MemberPage() {
    const [focusedUnitIndex, setFocusedUnitIndex] = useState(0);
    const [data, setData] = useState({});
    const [isMobile, setIsMobile] = useState(false);
    const scrollRef = useRef(null);
    const observerRef = useRef(null);

    const unitDisplayNames = {
        FLUTTER: "Flutter",
        SPRING: "Spring",
        REACT: "React",
        AI_RESEARCH: "AI R&D",
        DESIGN: "Design"
    };

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const callback = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const unitId = entry.target.id;
                    const unitIndex = Object.keys(data).findIndex(key => key === unitId);
                    if (unitIndex !== -1) {
                        setFocusedUnitIndex(unitIndex);
                    }
                }
            });
        };

        observerRef.current = new IntersectionObserver(callback, options);

        Object.keys(data).forEach(unit => {
            const element = document.getElementById(unit);
            if (element) {
                observerRef.current.observe(element);
            }
        });

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [data]); 

    useEffect(() => {
        axios.get("https://air.aiia-gcu.com/user/all", {
            withCredentials: true
        })
            .then(response => {
                if (response.data.statusCode === 200) {
                    const membersByUnit = {};
                    const validUnits = Object.keys(unitDisplayNames);

                    response.data.data.forEach(member => {
                        const { name, profileImage, major, position, unit } = member;
                        if (unit && validUnits.includes(unit)) {
                            const displayUnit = unitDisplayNames[unit];

                            if (!membersByUnit[displayUnit]) {
                                membersByUnit[displayUnit] = [];
                            }
                            membersByUnit[displayUnit].push({
                                name,
                                profileImage: profileImage || null,
                                department: major,
                                role: position || "Member",
                                unit: [displayUnit]
                            });
                        }
                    });
                    setData(membersByUnit);
                }
            })
            .catch(error => console.error("Error fetching members:", error));
    }, []);

    const handleClick = (index) => {
        setFocusedUnitIndex(index);

        const keys = Object.keys(data);
        const element = document.getElementById(keys[index]);
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "center"
            });
        }
    };

    return (
        <div className="screen bg-image-gradient">
            <div className="member-page" ref={scrollRef}>
                {!isMobile && (
                    <_DotsPagination
                        focusedIndex={focusedUnitIndex}
                        handleClick={handleClick}
                    />
                )}
                <div className="scrollable-container">
                    {Object.keys(data).map((unit, index) => (
                        <_UnitContainer
                            key={index}
                            unit={unit}
                            members={data[unit]}
                        />
                    ))}
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default MemberPage;