import {createSlice} from "@reduxjs/toolkit";
import {ActionType} from "../../_ActionType";
import {
    requestAttendanceCodeAction,
    fetchAllAttendanceRateAction,
    fetchDailyAttendanceAction,
    fetchMonthlyAttendanceAction,
    fetchRequiredUserListAction,
    setFocusedDate, modifyDailyAttendanceAction
} from "./AttendanceManagementAction";

export function AttendanceManagementReducer() {
    return createSlice({
        name: "admin/attendance",
        initialState: {
            focusedDate: (new Date()).toString(),
            monthlyAttendance: {},
            dailyAttendance: [],
            allAttendance: [],
            requiredUserList: {},
            code: null,
            status: ActionType.IDLE,
            error: null,
        },
        extraReducers: (builder) => {
            builder
                .addCase(setFocusedDate, (state, action) => {
                    state.focusedDate = action.payload;
                })

            builder
                .addCase(fetchMonthlyAttendanceAction.pending, (state, _) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(fetchMonthlyAttendanceAction.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.monthlyAttendance = action.payload;
                })
                .addCase(fetchMonthlyAttendanceAction.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });

            builder
                .addCase(fetchDailyAttendanceAction.pending, (state, _) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(fetchDailyAttendanceAction.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.dailyAttendance = action.payload;
                })
                .addCase(fetchDailyAttendanceAction.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });

            builder
                .addCase(modifyDailyAttendanceAction.pending, (state, _) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(modifyDailyAttendanceAction.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                })
                .addCase(modifyDailyAttendanceAction.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });

            builder
                .addCase(fetchRequiredUserListAction.pending, (state, _) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(fetchRequiredUserListAction.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.requiredUserList = action.payload;
                    console.log(state.requiredUserList);
                })
                .addCase(fetchRequiredUserListAction.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });

            builder
                .addCase(fetchAllAttendanceRateAction.pending, (state, _) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(fetchAllAttendanceRateAction.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.allAttendance = action.payload;
                })
                .addCase(fetchAllAttendanceRateAction.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });
            builder
                .addCase(requestAttendanceCodeAction.pending, (state, _) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(requestAttendanceCodeAction.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.code = action.payload;
                })
                .addCase(requestAttendanceCodeAction.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });
        }
    });
}