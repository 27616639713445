import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import {Col} from "react-bootstrap";
import {
    TextInfoInputForm,
    DropdownInfoInputForm,
} from "../../../../component/input_form/user_info_input_form/UserInfoInputForm";
import {useDispatch} from "react-redux";
import {registerUserAction} from "../../../../redux/admin_service/user_management/UserManagementAction";
import RoleEnum from "../../../../dto/enum/RoleEnum";
import UnitEnum from "../../../../dto/enum/UnitEnum";
import PermissionEnum from "../../../../dto/enum/PermissionEnum";
import {useNavigate} from "react-router-dom";

function UserRegisterPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [name, setName] = useState();
    const [studentId, setStudentId] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [email, setEmail] = useState();
    const [role, setRole] = useState();
    const [unit, setUnit] = useState();
    const[major,setMajor] =useState()
    const [permissions, setPermissions] = useState({
        attendance: false, schedule: false, news: false,
    });

    const roleOptions = RoleEnum.values;
    const unitOptions = UnitEnum.values;

    const handlePermissionChange = (value, checked) => {
        value = PermissionEnum.cvtToEN(value);
        let newPermissions = {...permissions};
        newPermissions[value] = checked;
        setPermissions(newPermissions);
    };

    const validate = () => {
        if (username === undefined || username === "") {
            throw new Error("아이디를 입력해주세요.");
        }
        if (password === undefined || password === "") {
            throw new Error("패스워드를 입력해주세요.");
        }
        if (name === undefined || name === "") {
            throw new Error("이름을 입력해주세요.");
        }
        if (studentId === undefined || studentId === "") {
            throw new Error("학번을 입력해주세요.");
        }
        if (phoneNumber === undefined || phoneNumber === "") {
            throw new Error("전화번호를 입력해주세요.");
        }
        if (email === undefined || email === "") {
            throw new Error("이메일을 입력해주세요.");
        }
        if (role === undefined || role === "") {
            throw new Error("역할을 선택해주세요.");
        }
        if (unit === undefined || unit === "") {
            throw new Error("유닛을 선택해주세요.");
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            validate();
            const resultAction = await dispatch(registerUserAction({
                username,
                password,
                name,
                studentId,
                phoneNumber,
                email,
                major,
                role,
                unit
            }));

            if (registerUserAction.fulfilled.match(resultAction)) {
                alert("회원 등록이 완료되었습니다.");
                navigate(0);
            } else {
                const errorMessage = resultAction.payload || "회원 등록 중 오류가 발생했습니다.";
                alert(errorMessage);
            }
        } catch (error) {
            alert(error.message || "회원 등록 중 오류가 발생했습니다.");
        }
    };

    return (
        <Col style={{
            width: "80%",
            // paddingTop: "calc(250 * var(--bs-aspect-ratio-height))",
            // paddingBottom: "calc(200 * var(--bs-aspect-ratio-height))",
            overflowY: "auto"
        }}>
            <h4
                className="h4-txt"
                style={{marginBottom: 24, color: "var(--bs-primary)"}}>
                회원 등록
            </h4>

            <form onSubmit={handleSubmit}>
                <TextInfoInputForm label="ID" hint="아이디 입력" value={username} onChange={(e) => setUsername(e.target.value)}/>
                <TextInfoInputForm label="PW" hint="패스워드 입력" value={password} onChange={(e) => setPassword(e.target.value)}/>
                <TextInfoInputForm label="이름" hint="이름 입력" value={name} onChange={(e) => setName(e.target.value)}/>
                <TextInfoInputForm label="학번" hint="학번 입력" value={studentId} onChange={(e) => setStudentId(e.target.value)}/>
                <TextInfoInputForm label="번호" hint="전화번호 입력" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                <TextInfoInputForm label="메일" hint="이메일 입력" value={email} onChange={(e) => setEmail(e.target.value)}/>
                <TextInfoInputForm label="전공" hint="전공 입력" value={major} onChange={(e) => setMajor(e.target.value)}/>
                <DropdownInfoInputForm label="역할" items={roleOptions} value={role} onChange={(e) => setRole(e.target.value)}/>
                <DropdownInfoInputForm label="유닛" items={unitOptions} value={unit} onChange={(e) => setUnit(e.target.value)}/>

                <Button variant="primary" type="submit">Submit</Button>
            </form>
        </Col>
    );
}

export default UserRegisterPage;