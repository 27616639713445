import axios from "axios";
import {instance} from "../_base";

/**
 * 유저 등록
 *
 * url: /user/signup
 * method: POST
 *
 * request: {
 *     username<string>: 로그인 ID,
 *     password<string>: 로그인 PW,
 *     name<string>: 이름,
 *     grade<number>: 학년,
 *     major<string>: 전공,
 *     position<string>: 조직 내 역할,
 *    `- 0: 회장
 *     - 1: 부회장
 *     - 2: 운영진
 *     - 3: 정규 부원
 *     - 4: 신입 부원
 * }
 *
 * response: {
 *      statusCode<number>: 200/400,
 *      statusMessage<string>: "성공"/"실패",
 * }
 *
 * return: (성공시) 200
 */
function signup({
                    username,
                    password,
                    name,
                    grade,
                    major,
                    position,
                    unit, phoneNumber, email
                }) {
    return new Promise((resolve, reject) => {
        console.log("[API:signup] start");
        let params = { username, password, name, grade, major, position, unit, phoneNumber,email};
        instance.post("/auth/signup", params)
            .then((response) => {
                console.log("[API:signup] success (200)", response.data);
                resolve();
            })
            .catch((error) => {
                console.log(`[API:signup] error`, error);
                if (error.response) {
                    console.log(`[API:signup] error response:`, error.response.data);
                }
                reject(new Error(error));
            });
    });
}


/**
 * 유저 정보 수정
 *
 * url: /user/#uid
 * method: PATCH
 *
 * request: {
 *     uid<number>: 유저 고유 ID (*query param),
 *     id<string>: 로그인 ID,
 *     password<string>: 로그인 PW,
 *     major<string>: 전공,
 *     role<string>: 조직 내 역할,
 *     unit<string>: 유닛,
 *     permission<string>: AIR 권한
 * }
 *
 * response: {
 *     statusCode<number>: 200/400,
 *     statusMessage<string>: "성공"/"실패",
 * }
 *
 * return: (성공시) 200
 */
function modifyUser({
    uid,
    id,
    password,
    major,
    role,
    unit,
    permission
}) {
    return new Promise((resolve, reject) => {
        console.log("[API] modifyUser");
        let params = { id, password, major, role, unit, permission };
        axios.patch("/user/#" + uid, params)
            .then((response) => {
                console.log("[API] success (200)");
                resolve(200);
            })
            .catch((error) => {
                console.log(`[API] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

/**
 * 유저 삭제
 *
 * url: /user/#uid
 * method: DELETE
 *
 * request: {
 *     uid<number>: 유저 고유 ID (*query param)
 * }
 *
 * response: {
 *     statusCode<number>: 200/400,
 *     statusMessage<string>: "성공"/"실패",
 * }
 *
 * return: (성공시) 200
 */
function deleteUser({ uid }) {
    return new Promise((resolve, reject) => {
        console.log("[API] deleteUser");
        axios.delete("/user/#" + uid)
            .then((response) => {
                console.log("[API] success (200)");
                resolve(200);
            })
            .catch((error) => {
                console.log(`[API] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

/**
 * 유저 전체 정보 조회
 *
 * url: /user
 * method: GET
 *
 * response: {
 *     statusCode<number>: 200/400,
 *     statusMessage<string>: "성공"/"실패",
 *     data<array>: [
 *         {
 *             uid<number>: 유저 고유 ID,
 *             name<string>: 이름,
 *             studentId<string>: 학번,
 *             major<string>: 전공,
 *             role<string>: 조직 내 역할,
 *             unit<string>: 유닛,
 *             permission<string>: AIR 권한
 *             point<number>: 포인트
 *         }
 *     ]
 * }
 *
 * return: response.data
 */
function getAllUsers() {
    return new Promise((resolve, reject) => {
        console.log("[API] getAllUsers");
        axios.get("/user")
            .then((response) => {
                console.log("[API] success (200)");
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`[API] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

export {
    signup,
    modifyUser,
    deleteUser,
    getAllUsers
}