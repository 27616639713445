import React, {useEffect, useState} from 'react';
import { TextField } from '@mui/material';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPenToSquare} from "@fortawesome/free-solid-svg-icons";

function NoteInputForm({
    value,
    onUpdated
}) {
    const [isEditable, setIsEditable] = useState(false);
    const [text, setText] = useState(value);

    const inputStyle = isEditable ? {
        height: 36,
        borderRadius: 8,
        backgroundColor: 'white',
    } : {
        height: 36,
        borderRadius: 8,
        backgroundColor: "none",
    }

    const sxStyle = {
        "& fieldset": isEditable ? {
            border: '1px solid var(--sub-title6)',
        } : {
            border: 'none',
        },
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "black",
        }
    }

    const handleEditClick = () => {
        if (isEditable) onUpdated(text);
        setIsEditable(!isEditable);
    };

    useEffect(() => {
        setText(value);
    }, [value]);

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center'
        }}>
            <TextField
                className="body3-txt"
                value={text}
                onChange={(e) => setText(e.target.value)}
                disabled={!isEditable}
                fullWidth
                slotProps={{
                    input: {style: inputStyle},
                }}
                sx={sxStyle}
            />
            <span style={{ padding: 10 }}>
                <FontAwesomeIcon
                    icon={isEditable ? faCheck : faPenToSquare}
                    onClick={handleEditClick}
                    size={'lg'}
                />
            </span>
        </div>
    );
}

export default NoteInputForm;