import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Checkbox} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

function UserRow({teamName, usersOfTeam, handleCheckUser, handleCheckTeam}) {
    const [list, setList] = useState([...usersOfTeam.map((value) => value['attendanceRequired'])]);

    const isAllChecked = list.every((value) => value === true);

    console.log(teamName, list, isAllChecked);

    return (
        <div style={{padding: "20px 0"}}>
            <div style={{marginBottom: 24, display: "flex", alignItems: "center"}}>
                <span>
                    <Checkbox
                        checked={isAllChecked}
                        onChange={(_) => {
                            let newList = Array(list.length).fill(!isAllChecked);
                            setList(newList);
                            handleCheckTeam(teamName, !isAllChecked);
                        }}
                        sx={{'& .MuiSvgIcon-root': { fontSize: 28 }}}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </span>
                <span
                    className="modal-txt"
                    style={{ color: "var(--bs-primary)" }}
                >{teamName}</span>
            </div>

            <Grid container rowSpacing="10px" columnSpacing="20px">
                {usersOfTeam.map((user, index) => (
                    <div key={`${teamName}-${index}`}>
                        <span>
                            <Checkbox
                                checked={list[index]}
                                onChange={(_) => {
                                    let newList = [...list];
                                    newList[index] = !list[index];
                                    setList(newList);
                                    handleCheckUser(user.userId, !list[index]);
                                }}
                                sx={{'& .MuiSvgIcon-root': { fontSize: 28 }}}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </span>
                        <span
                            className="body3-txt"
                            style={{color: "var(--dark-gray)"}}
                        >{user.name}</span>
                    </div>
                ))}
            </Grid>
        </div>
    );
}

export function MemberCheckList({
    userList,
    handleCheckUser,
    handleCheckTeam,
}) {
    return (
        <TableContainer
            component={Paper}
            style={{
                marginBottom: 16,
                "--Paper-shadow": "none",
                borderTop: "4px solid var(--bs-primary)",
                borderBottom: "none",
                borderRadius: 0,
            }}
        >
            <Table>
                <TableBody>
                    {Object.keys(userList).map((team, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <UserRow
                                    teamName={team}
                                    usersOfTeam={userList[team]}
                                    handleCheckUser={handleCheckUser}
                                    handleCheckTeam={handleCheckTeam}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


