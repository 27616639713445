import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import CalendarModal from './CalendarModal';
import CalendarList from './CalendarList';
import './CalendarPage.css';
import { useWindowSize } from 'react-use';
import SideBar from '../../../component/side_bar/SideBar';
import Footer from '../../../component/footer/Footer';
import SubCalendar from "./SubCalendar";
import { fetchMonthEvents, updateSchedule } from '../../../redux/user_service/calendar/UserCalendarAction';
import { setModal, setNewEvents, setSelectedDate } from '../../../redux/user_service/calendar/UserCalendarReducer';

function CalendarPage() {
    const dispatch = useDispatch();
    const { width, height } = useWindowSize();
    const {
        selectedDate,
        monthEvents,
        modal,
        newEvents,
        error
    } = useSelector(state => state.calendar);
    let selectedDateObj = new Date(selectedDate);

    useEffect(() => {
        dispatch(fetchMonthEvents(selectedDate));
    }, [dispatch, selectedDate]);

    const handleDateClick = (value, event) => {
        if (height > width) {
            alert("모바일에서는 일정 추가 및 수정이 불가능합니다.");
            return;
        }

        dispatch(setSelectedDate(value.toString()));
        const dateKey = dayjs(value).format('YYYY-MM-DD');
        const events = monthEvents[dateKey] || [];
        dispatch(setNewEvents(events));
        dispatch(setModal(true));
    };

    const handleEventChange = (index, field, value) => {
        const updatedEvents = [...newEvents];
        updatedEvents[index] = {
            ...updatedEvents[index],
            [field]: value || null
        };
        dispatch(setNewEvents(updatedEvents));
    };


    const handleAddInput = () => {
        if (newEvents.length >= 3) return;
        dispatch(setNewEvents([
            ...newEvents,
            { title: '', startTime: null, endTime: null }
        ]));
    };

    const handleDelete = (index) => {
        dispatch(setNewEvents(newEvents.filter((_, i) => i !== index)));
    };

    const handleSave = async () => {
        try {
            const formattedEvents = newEvents.map(event => ({
                name: event.title || '',
                startAt: event.startTime ? dayjs(event.startTime).format('HH:mm') : '00:00',
                endAt: event.endTime ? dayjs(event.endTime).format('HH:mm') : '23:59',
            }));

            await dispatch(updateSchedule({
                date: selectedDateObj.toString(),
                events: formattedEvents,
            }));

            dispatch(setNewEvents([])); // 이벤트 초기화
        } catch (error) {
            console.error('Error saving events:', error);
        }
    };



    return (
        <div className="screen" style={{display: "flex", flexDirection: "row"}}>
            <SideBar
                width="calc(480 * var(--bs-aspect-ratio-width))"
                padding="calc(240 * var(--bs-aspect-ratio-height)) 0 calc(100 * var(--bs-aspect-ratio-height)) 0">
                <SubCalendar/>
            </SideBar>
            <div
                style={{
                    width: 'calc(1032 * var(--bs-aspect-ratio-width))',
                    height: '100%',
                    display: 'flex',
                    paddingTop: 'calc(240 * var(--bs-aspect-ratio-height))',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Calendar
                    locale="en-US"
                    value={selectedDateObj}
                    next2Label={null}
                    prev2Label={null}
                    showNeighboringMonth={false}
                    tileContent={({date}) => <CalendarList events={monthEvents} date={date}/>}
                    onClickDay={handleDateClick}
                />
                <Footer sidePosition/>
            </div>
            {modal && (
                <CalendarModal
                    selectedDate={selectedDateObj}
                    newEvents={newEvents}
                    handleEventChange={handleEventChange}
                    handleAddInput={handleAddInput}
                    handleSave={handleSave}
                    handleClose={() => dispatch(setModal(false))}
                    handleDelete={handleDelete}
                />
            )}
            {error && <div className="error-message">{error}</div>}
        </div>
    );
}

export default CalendarPage;