import {VirtuosoTableComponents} from "../../../../component/table/VirtuosoTableComponents";
import {fixedHeaderContent} from "../../../../component/table/FixedHeaderContent";
import {rowContent} from "../../../../component/table/RowContent";
import {TableVirtuoso} from "react-virtuoso";
import React from "react";
import DropdownChip from "../../../../component/dropdown/dropdown_chip/DropdownChip";
import {Checkbox} from "@mui/material";

const setContent = (index, row, attribute) => {
    return attribute.dataKey === 'unit' ? (
        <DropdownChip
            hint="유닛"
            init={
                row[attribute.dataKey] === "Flutter" ? 0 :
                row[attribute.dataKey] === "React" ? 1 :
                row[attribute.dataKey] === "Spring" ? 2 :
                row[attribute.dataKey] === "AI" ? 3 : 4
            }
            items={["Flutter", "React", "Spring", "AI", "Design"]}
            onClick={(index) => console.log(index)}
        />
    ) : attribute.dataKey === 'permission' ? (
        <div>{
            row[attribute.dataKey].map((item, index) => (
                <Checkbox
                    onClick={() => {}}
                    sx={{'& .MuiSvgIcon-root': { fontSize: 28 }}}
                />
            ))
        }</div>
    ) : row[attribute.dataKey];
}

export function CustomUT({data}) {
    const attributes = [
        { width: "5%", label: 'NO', dataKey: 'id', align: 'center' },
        { width: "7%", label: '이름', dataKey: 'name', align: 'center' },
        { width: "7%", label: '학번', dataKey: 'studentId', align: 'center' },
        { width: "10%", label: '학과', dataKey: 'department', align: 'center' },
        { width: "10%", label: '역할', dataKey: 'role', align: 'center' },
        { width: "10%", label: '유닛', dataKey: 'unit', align: 'center' },
        { width: "19%", label: '권한 (출석, 일정, 뉴스)', dataKey: 'permission', align: 'center' },
        { width: "10%", label: '포인트', dataKey: 'point', align: 'center' },
    ];

    return (
        <TableVirtuoso
            data={data}
            components={VirtuosoTableComponents}
            fixedHeaderContent={() => fixedHeaderContent(attributes)}
            itemContent={(index, row) => rowContent(index, row, attributes, setContent)}
            style={{
                marginBottom: 16,
                "--Paper-shadow": "none",
                borderTop: "4px solid var(--bs-primary)",
                borderBottom: "none",
                borderRadius: 0,
            }}
        />
    );
}