import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CustomAT, TableTitle} from "./_CustomAT";
import Button from "@mui/material/Button";
import {MemberCheckList} from "./_MemberCheckList";
import {
    fetchAllAttendanceRateAction,
    fetchDailyAttendanceAction,
    fetchRequiredUserListAction, modifyDailyAttendanceAction,
    modifyRequiredUserList
} from "../../../../redux/admin_service/attendance_management/AttendanceManagementAction";
import AttendanceStatusEnum from "../../../../dto/enum/AttendanceStatusEnum";

function AttendanceTablePage() {
    const navigator = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const selector = useSelector((state) => state.attendanceManagement);

    const [changingAttendanceListFlag, setChangingAttendanceListFlag] = useState(false);
    const [changedList, setChangedList] = useState({});

    let attributes, attendanceData, date;

    if (location.pathname.includes("daily")) {
        const searchParams = new URLSearchParams(location.search);
        date = searchParams.get("date");

        attributes = [
            {width: "10%", label: 'NO', dataKey: 'id', align: "center"},
            {width: "15%", label: '이름', dataKey: 'name', align: "center"},
            {width: "15%", label: '출석', dataKey: 'status', align: "center"},
            {width: "60%", label: '비고', dataKey: 'note', align: "center"},
        ];
        attendanceData = selector.dailyAttendance;
    } else {
        attributes = [
            {width: "10%", label: 'NO', dataKey: 'id', align: "center"},
            {width: "20%", label: '이름', dataKey: 'name', align: "center"},
            {width: "12.5%", label: '출석횟수', dataKey: 'attendanceCount', align: "center"},
            {width: "12.5%", label: '지각횟수', dataKey: 'lateCount', align: "center"},
            {width: "12.5%", label: '결석횟수', dataKey: 'absenceCount', align: "center"},
            {width: "12.5%", label: '출석률', dataKey: 'attendanceRate', align: "center"},
            {width: "20%", label: '전체확인', dataKey: 'all', align: "center"},
        ]
        attendanceData = selector.allAttendance;
    }

    useEffect(() => {
        if (location.pathname.includes("daily")) {
            dispatch(fetchDailyAttendanceAction({date}));
        } else {
            dispatch(fetchAllAttendanceRateAction());
        }
    }, [dispatch]);

    const handleOnClick = async () => {
        let attendanceList = Object.keys(changedList).map((userId) => {
            return {
                userId: userId,
                status: changedList[userId].status,
                note: changedList[userId].note
            }
        });
        await dispatch(modifyDailyAttendanceAction({date, attendanceList}));
        setChangedList({});
    };

    const handleOnUpdateParticipantList = async () => {
        if (changingAttendanceListFlag) {
            let attendanceList = Object.keys(changedList).map((userId) => {
                return {
                    userId: userId,
                    status: changedList[userId].status,
                    note: changedList[userId].note
                }
            });
            await dispatch(modifyRequiredUserList({date, attendanceList}));
            setChangedList({});
        }
        else {
            await dispatch(fetchRequiredUserListAction({date}));
        }

        setChangingAttendanceListFlag(!changingAttendanceListFlag);
    }

    const handleCheckTeam = (teamName, value) => {
        let teamUsers = selector.requiredUserList[teamName].map((user) => user.userId);
        let temp = {...changedList};
        teamUsers.map((userId) => {
            temp[userId] = value;
        });
        setChangedList(temp);
    };

    const handleCheckUser = (userId, value) => {
        let temp = {...changedList};
        temp[userId] = value;
        setChangedList(temp);
    };

    const handleOnChangeAttendanceStatus = (userId, index) => {
        let newChangedList = {...changedList};
        let currentNote = changedList[userId] && changedList[userId].note
            ? changedList[userId].note
            : attendanceData.find((item) => item.userId === userId).note;

        newChangedList[userId] = {
            status: AttendanceStatusEnum.getKey(index),
            note: currentNote
        }
        setChangedList(newChangedList);
    }

    const handleOnChangeNote = (userId, value) => {
        let newChangedList = {...changedList};
        let currentStatus = changedList[userId] && changedList[userId].status
            ? changedList[userId].status
            : attendanceData.find((item) => item.userId === userId).status;

        newChangedList[userId] = {
            status: currentStatus,
            note: value
        }
        setChangedList(newChangedList);
    }

    return (
        <div style={{
            width: "100%",
            height: "100%",
            padding:
                "0 " +
                "calc(88 * var(--bs-aspect-ratio-width)) " +
                "calc(100 * var(--bs-aspect-ratio-height)) " +
                "calc(88 * (var(--bs-aspect-ratio-width)))",
            display: "flex",
            flexDirection: "column",
        }}>

            <TableTitle>
                {
                    location.pathname.includes("daily") ? (
                        <div style={{display: "flex", alignItems: "center"}}>
                            <span
                                style={{
                                    padding: 16,
                                    fontSize: 20,
                                    fontWeight: "normal"
                                }}
                                onClick={() => navigator("/admin/attendance/calendar")}>
                                {'<'}
                            </span>
                                <span style={{marginLeft: 12}}>
                                {date.replace(/-/g, ".")}
                            </span>
                            <span style={{paddingLeft: 16}}>
                                <Button
                                    className="sub-calendar-txt"
                                    style={{
                                        padding: "4px 12px",
                                        borderRadius: 8,
                                        backgroundColor: changingAttendanceListFlag ? "var(--blue3)" : "var(--bs-primary)",
                                        color: changingAttendanceListFlag ? "var(--bs-primary)" : "white",
                                    }}
                                    onClick={() => handleOnUpdateParticipantList()}
                                >
                                    {changingAttendanceListFlag ? "출석 명단 수정 완료" : "출석 명단 수정"}
                                </Button>
                            </span>
                            {
                                !changingAttendanceListFlag && Object.keys(changedList).length !== 0 && (
                                    <span style={{paddingLeft: 16}}>
                                        <Button
                                            className="sub-calendar-txt"
                                            onClick={handleOnClick}
                                            style={{
                                                padding: "4px 12px",
                                                borderRadius: 8,
                                                backgroundColor: "var(--blue3)",
                                                color: "var(--bs-primary)",
                                            }}>
                                            업데이트
                                        </Button>
                                    </span>
                                )
                            }
                        </div>
                    ) : (
                        <h4
                            className="h4-txt"
                            style={{marginBottom: 18, color: "var(--bs-primary)"}}>
                            멤버 출석률
                        </h4>
                    )
                }
            </TableTitle>

            {
                changingAttendanceListFlag ? (
                    <MemberCheckList
                        userList={selector.requiredUserList}
                        handleCheckTeam={handleCheckTeam}
                        handleCheckUser={handleCheckUser}
                    />
                ) : (
                    <CustomAT
                        attributes={attributes}
                        data={attendanceData}
                        onChangedAttendanceStatus={handleOnChangeAttendanceStatus}
                        onChangedNote={handleOnChangeNote}
                    />
                )
            }


            {
                attendanceData.length === 0 && (
                    <div
                        className="body3-txt"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "calc(50 * var(--bs-aspect-ratio-height))",
                            textAlign: "center",
                        }}
                    >
                        해당일에는 출석을 필요로 하는 멤버가 없습니다. <br/>
                        출석해야 하는 멤버가 있다면 출석 명단을 수정해 주세요.
                    </div>
                )
            }
        </div>
    );
}

export default AttendanceTablePage;